.container {
  color: var(--color-midnight-800);
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.alignTitleCenter {
  @media (--viewport-m) {
    text-align: center;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2.4rem;

  @media (--viewport-m) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-l) {
    grid-template-columns: repeat(3, 1fr);
    gap: 3.2rem;
  }
}

.titleLight {
  color: var(--color-white);
}
