.modalContainer {
  border-radius: var(--default-border-radius, var(--border-radius-l));
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  &.noBorderRadius,
  &.noBorderRadius .videoContainer {
    border-radius: 0;
  }
}

.videoContainer {
  align-items: center;
  background-color: var(--color-black);
  border: none;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  border-radius: var(--border-radius-l);
}

.playButton {
  aspect-ratio: 1;
  border-radius: var(--border-radius-full);
  inset: 0;
  box-sizing: content-box;
  height: 25%;
  margin: auto;
  pointer-events: none;
  position: absolute;

  & svg {
    background-color: rgb(var(--video-playbutton-border-rgb) / 60%);
    box-sizing: border-box;
    height: 100%;
    padding: 15%;
    width: 100%;

    & > path:first-child {
      fill: var(--video-playbutton-background-color, var(--color-riptide-300));
    }
  }

  &.rounded svg {
    border-radius: var(--border-radius-full);
  }

  &.smallButton {
    @media(--viewport-l){
      height: 15%;
    }
  }
}

.videoThumbnail {
  pointer-events: none;
  border-radius: var(--border-radius-l);
  width: 100%;

  & picture {
    height: auto;
    aspect-ratio: 16 / 9;

    & img {
      object-fit: cover;
      height: 100%;
    }
  }
}

.videoTitle {
  bottom: 0;
  color: var(--color-white);
  font-family: var(--font-family-heading);
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-medium);
  padding: 2.4rem;
  position: absolute;
  text-shadow: 0 2rem 2.5rem rgb(0 0 0 / 10%), 0 1rem 1rem rgb(0 0 0 / 4%);
}

.modal {
  aspect-ratio: 16 / 9;
  border-radius: var(--border-radius-l);
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
  height: 100%;

  &::before {
    background-color: var(--color-white);
    content: "";
    height: 100%;
    opacity: 0.2;
    position: absolute;
    width: 100%;
  }

  &.modalOpen {
    display: flex;
  }

  & .videoPlayer {
    width: 100%;
    height: 100%;
    border-radius: calc(var(--border-radius-l) + 0.1rem);
  }

  & .modalContent {
    height: 100%;
    padding: 0;
    position: relative;
    width: 100%;

    & .closeButton {
      background-color: var(--video-playbutton-background-color, var(--color-riptide-300));
      border-radius: var(--border-radius-l);
      border: none;
      cursor: pointer;
      height: 5rem;
      position: absolute;
      right: -0.1rem;
      top: -0.1rem;
      width: 5rem;

      & svg {
        width: 4rem;
      }
    }
  }
}

.autoplayVideo {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &.videoInHexagon {
    position: absolute;
  }

  &.resizeToContainer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.autoplayContainer {
  position: relative;
  height: 100%;
}

.soundToggleWrapper {
  z-index: 1;
  position: absolute;
  right: var(--max-width-padding-s);
  top: var(--max-width-padding-s);
  height: auto !important;
  opacity: 0;
  animation: pulse-in-with-opacity 0.8s 2.5s cubic-bezier(0, 0.65, 0, 0.95) forwards;  

  @media (--viewport-m) {
    right: var(--max-width-padding-m);
    top: var(--max-width-padding-m);
  }
}

@keyframes pulse-in-with-opacity {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}