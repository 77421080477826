.container:hover, .container:focus {
  & picture {
    &::before {
      --height: 80%;

      opacity: 0.2;
    }

    & > img {
      transform: scale(1.05);
    }
  }
}

.imageHover {
  width: 100%;

  & > picture {
    /* This overlay will give a nice effect on hover. */
    &::before {
      --height: 50%;

      content: "";
      display: block;
      z-index: 1;
      position: absolute;
      inset: 0 0 auto;
      height: var(--height, 50%);
      pointer-events: none;
      opacity: 0;
      transition: height 0.8s ease, opacity 0.3s ease;
      background:
        linear-gradient(
          0deg,
          rgb(0 0 0 / 0%),
          #242628
        );
    }
  }

  & img {
    transition: transform 0.3s ease;
  }
}