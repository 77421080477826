.container {
  background-color: var(--color-white);
  border-radius: var(--page-preview-item-container-border-radius, var(--border-radius-l));
  border-bottom-left-radius: var(--page-preview-item-container-border-bottom-radius, var(--border-radius-l));
  border-bottom-right-radius: var(--page-preview-item-container-border-bottom-radius, var(--border-radius-l));
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  overflow: hidden;

  & picture {
    /* stylelint-disable-next-line max-line-length */
    border-bottom-left-radius: var(--page-preview-item-container-border-bottom-radius, var(--page-preview-item-container-border-radius), 0);
    /* stylelint-disable-next-line max-line-length */
    border-bottom-right-radius: var(--page-preview-item-container-border-bottom-radius, var(--page-preview-item-container-border-radius), 0);
  }

  &:hover .textContainer h2,
  &:focus .textContainer h2 {
    text-decoration: underline;
  }

  &.backgroudColorGrey {
    background-color: var(--color-rolling-stone-050);
  }
}

.textContainer {
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  & h2 {
    color: var(--color-midnight-900);
    font-size: var(--font-size-xl);
    margin: 0;

    & strong {
      color: var(--color-midnight-900);
      font-size: var(--font-size-xl);
    }
  }

  & p {
    margin: 0;
    color: var(--color-midnight-900);
  }
}

.badgesContainer {
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}


.previewText {
  display: flex;
  flex-wrap: wrap;
  gap: .6rem;

  & p {
    margin-bottom: 0 !important;
  }
}

.location {
  white-space: nowrap;
  color: var(--page-preview-item-location-color, currentcollor) !important;
}