.container {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.reversed {
  @media (--viewport-m) {
    flex-direction: row-reverse;
  }
}

.alignCenter {
  @media (--viewport-m) {
    align-items: center;
  }
}
