.small {
  margin: 0 0 0.8rem;

  @media (--viewport-l) {
    margin: 0 0 1.6rem;
  }
}

.medium {
  margin: 0 0 1.6rem;

  @media (--viewport-l) {
    margin: 0 0 2.4rem;
  }
}

.large {
  margin: 0 0 2.4rem;

  @media (--viewport-l) {
    margin: 0 0 3.2rem;
  }
}

.extralarge {
  margin: 0 0 3.2rem;

  @media (--viewport-l) {
    margin: 0 0 4.8rem;
  }
}
