.container {
  position: relative;
}

.slidesContainerContainer {
  position: relative;
  overflow: hidden;

  &.fullSize,
  &.fullSize .slidesContainer {
    max-height: min(65vh, 80rem);
  }
}

.slidesContainer {
  position: relative;
  display: flex;
  transition: transform 800ms cubic-bezier(0.45, 0, 0.55, 1);

  & > * {
    flex-shrink: 0;
  }
}

.buttonsContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 1.6rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  & > * {
    pointer-events: auto;
  }

  &.isFullHeight {
    height: 100%;
  }

  @media (--viewport-m) {
    padding: 3.2rem;
  }
}

.button {
  align-items: center;
  background: var(--button-basic-rectangle-surface, var(--color-midnight-900));
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 4rem;
  justify-content: center;
  width: 4rem;
  transition: background 200ms ease-out;

  &:hover,
  &:focus {
    background: var(--button-basic-rectangle-surface-hover, var(--color-midnight-800));
  }

  & svg {
    fill: var(--color-white);
    height: 2.4rem;
    margin: 0;
    width: 2.4rem;
  }

  &:first-child svg {
    transform: rotate(90deg);
  }

  &:last-child svg {
    transform: rotate(-90deg);
  }
}

.indicatorsContainer {
  bottom: 0;
  left: 0;
  padding: 1.6rem;
  position: absolute;
  width: 100%;

  @media (--viewport-m) {
    padding: 3.2rem;
  }
}

.slide {
  color: var(--color-white);
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
}

.slideContent,
.slideContentContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & h2 {
    font-size: var(--font-size-l);

    @media (--viewport-m) {
      font-size: var(--font-size-4xl);
    }
  }
}

.caption {
  margin-top: -0.8rem;
  font-size: var(--font-size-s);
  max-width: 50%;

  &.captionRightTop {
    top: 1.6rem;
    right: 1.6rem;
    position: absolute;

    @media (--viewport-m) {
      top: 3.2rem;
      right: 3.2rem;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(0 29 58 / 35%) 0%, rgb(0 29 58 / 0%) 50%);
}

.slideContentContainer {
  padding: 1.6rem;

  @media (--viewport-m) {
    padding: 3.2rem;
  }
}

.indicators {
  display: flex;
  gap: 1.6rem;

  @media (--viewport-m) {
    gap: 3.2rem;
  }
}

.indicator {
  height: 0.2rem;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius-s);
  opacity: 0.4;
  transition: opacity 200ms ease-out;

  &.isActive {
    opacity: 1;
  }
}

.slide picture {
  height: 100%;

  > img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
