.container {
  background-color: var(--block-container-background-color-default, var(--color-rolling-stone-100));
  color: var(--block-container-surface-color-default);
  padding: 4rem 0;

  & p a {
    color: var(--block-container-surface-link-color, var(--color-midnight-400));

    &:hover {
      text-decoration: none;
    }
  }

  @media (--viewport-m) {
    padding: 8rem 0;
  }
}

.blue {
  background-color: var(--block-container-background-color-blue, var(--color-midnight-900));
  color: var(--block-container-surface-color-blue, var(--color-white));

  & p a {
    color: var(--block-container-surface-link-color-blue, var(--color-riptide-300));

    &:hover {
      text-decoration: none;
    }
  }

  & :has(h1, h2, h3, h4) strong {
    /* stylelint-disable-next-line rule */
    color: var(--block-container-surface-color-blue, var(--color-white));
  }
}

.gray {
  background-color: var(--block-container-background-color-gray, var(--color-rolling-stone-100));
  color: var(--block-container-surface-color-gray);
}

.lightGray {
  background-color: var(--block-container-background-color-light-gray, var(--color-rolling-stone-050));
  color: var(--block-container-surface-color-light-gray);

  & [class*="ContentCard_hasInnerContentBox"] {
    background-color: var(--color-white);
  }
}

.white {
  background-color: var(--block-container-background-color-white, var(--color-white));
  color: var(--block-container-surface-color-white);

  & [class*="Pagination_container"] [class*="Pagination_item"]:not([class*="Pagination_active"]) {
    color: var(--block-container-surface-color-white);
  }
}

.black {
  background-color: var(--color-rolling-stone-900);

  & button, button svg {
    color: var(--color-white);
  }

  & button svg {
    fill: var(--color-white); 
  }
}

.lightGrayBlue,
.blueLightGray,
.blueGray,
.blueWhite,
.lightGrayWhite {
  --gradient-height: 8rem;

  padding-bottom: 0;
  background: var(--gradient-first-color);

  &::after {
    content: '';
    width: 100%;
    height: var(--gradient-height);
    display: block;
    background-color: var(--gradient-second-color);
    margin-top: calc(var(--gradient-height) * -1);
    pointer-events: none;
  }

  @media (--viewport-s) {
    --gradient-height: 12rem;    
  }

  @media (--viewport-m) {
    & [class*="FlexRow"] > * {
      margin-bottom: var(--gradient-height);
      padding-bottom: 10rem;

      &[class*="RemoveGradientMargin"], &[class*="Pentagon"] {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  & + * {
    padding-top: 0;

    @media (--viewport-m) {
      padding-top: 0;
    }
  }
}

.lightGrayBlue {
  --gradient-first-color: var(--block-container-background-color-light-gray, var(--color-rolling-stone-050));
  --gradient-second-color: var(--block-container-background-color-blue, var(--color-midnight-900));
}

.blueLightGray {
  color: var(--color-white);

  --gradient-first-color: var(--block-container-background-color-blue, var(--color-midnight-900));
  --gradient-second-color: var(--block-container-background-color-light-gray, var(--color-rolling-stone-050));
}

.blueGray {
  color: var(--block-container-background-color-gray, var(--color-rolling-stone-100));

  --gradient-first-color: var(--block-container-background-color-blue, var(--color-midnight-900));
  --gradient-second-color: var(--block-container-background-color-gray, var(--color-rolling-stone-100));
}

.blueWhite {
  color: var(--block-container-background-color-gray, var(--color-rolling-stone-100));

  --gradient-first-color: var(--block-container-background-color-blue, var(--color-midnight-900));
  --gradient-second-color: var(--color-white);
}

.lightGrayGray {
  background:
    linear-gradient(
      var(--block-container-background-color-light-gray, var(--color-rolling-stone-050)) 0%,
      var(--block-container-background-color-light-gray, var(--color-rolling-stone-050)) 50%,
      var(--block-container-background-color-gray, var(--color-rolling-stone-100)) 50%,
      var(--block-container-background-color-gray, var(--color-rolling-stone-100)) 100%
    );
}

.lightGrayWhite {
  --gradient-first-color: var(--color-rolling-stone-050);
  --gradient-second-color: var(--color-white);
}

.smallPaddingTop {
  padding-top: 4rem;
}

.smallPaddingBottom {
  padding-bottom: 2rem;
}

.noDesktopPaddingTopBottom {
  @media (--viewport-m) {
    padding-top: 0;
    padding-bottom: 0;
  }
}