.imageBadge {
  background-color: var(--color-white);
  border: none;
  border-radius: var(--border-radius-full);
  color: var(--color-midnight-900);
  cursor: pointer;
  padding: 0.4rem 1.2rem;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  font-size: 1.3rem;
  min-height: 3.2rem;

  &.href {
    &:hover,
    &:focus {
      background-color: var(--image-badge-hover-background-color, var(--color-riptide-300));
      color: var(--image-badge-hover-color, inherit);
    }
  }
}
