.siteLabelPill {
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--border-radius-full);
  display: flex;
  font-size: var(--font-size-m);
  gap: 0.8rem;
  line-height: var(--line-height-xs);
  padding: 0.8rem;
  color: var(--site-label-pill-color, currentcolor);

  & > * {
    display: block;
    flex-shrink: 0;
  }

  & > svg {
    height: 1.6rem;
    width: 1.6rem;
  }
}
