.figure {
  margin: 0;
  position: relative;
}

.caption {
  color: var(--color-white);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  max-width: 50%;
  padding: 2.4rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

  &&::before {
    content: "";
    background: linear-gradient(200deg, rgb(0 0 0 / 85%) 0%, rgb(0 0 0 / 0%) 45%);
    height: 200%;
    position: absolute;
    right: 0;
    top: 0;
    width: 200%;
    z-index: -1;
  }
}
